$font-path: '~/assets/fonts';

@font-face {
  font-family: "watchman-font";
  src: url("#{$font-path}/watchman-font.eot");
  src: url("#{$font-path}/watchman-font.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}/watchman-font.woff") format("woff"),
  url("#{$font-path}/watchman-font.ttf") format("truetype"),
  url("#{$font-path}/watchman-font.svg#watchman-font") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "watchman-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "watchman-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-add-icon:before {
  content: "\6d";
}
.icon-account-sett-icon:before {
  content: "\6e";
}
.icon-active-info-icon:before {
  content: "\6f";
}
.icon-add-apple-icon:before {
  content: "\70";
}
.icon-add-folder-icon:before {
  content: "\71";
}
.icon-add-icon:before {
  content: "\72";
}
.icon-add-rule-icon:before {
  content: "\73";
}
.icon-add-users-icon:before {
  content: "\74";
}
.icon-admin-guide-icon:before {
  content: "\75";
}
.icon-alert-msg-icon:before {
  content: "\76";
}
.icon-android-icon:before {
  content: "\77";
}
.icon-apple-icon:before {
  content: "\78";
}
.icon-apps-icon:before {
  content: "\79";
}
.icon-backup-icon:before {
  content: "\7a";
}
.icon-bar-graph-icon:before {
  content: "\41";
}
.icon-block-cleaning-icon:before {
  content: "\42";
}
.icon-block-device-icon:before {
  content: "\43";
}
.icon-block-knox-icon:before {
  content: "\44";
}
.icon-block-screen-icon:before {
  content: "\45";
}
.icon-block-shutdown-icon:before {
  content: "\46";
}
.icon-block-uninstall-icon:before {
  content: "\47";
}
.icon-block-user-icon:before {
  content: "\48";
}
.icon-bookmark-icon:before {
  content: "\49";
}
.icon-calendar-icon:before {
  content: "\4a";
}
.icon-camera-icon:before {
  content: "\4b";
}
.icon-change-pass-icon:before {
  content: "\4c";
}
.icon-cleaning-icon:before {
  content: "\4d";
}
.icon-clear-data2-icon:before {
  content: "\4e";
}
.icon-clear-data-icon:before {
  content: "\4f";
}
.icon-cointainer-lock-res-icon:before {
  content: "\50";
}
.icon-configuration-android-icon:before {
  content: "\51";
}
.icon-configuration-icon:before {
  content: "\52";
}
.icon-configuration-ios-icon:before {
  content: "\53";
}
.icon-connection-icon:before {
  content: "\54";
}
.icon-contacts-icon:before {
  content: "\55";
}
.icon-copy-icon:before {
  content: "\56";
}
.icon-critical-icon:before {
  content: "\57";
}
.icon-dangerous-icon:before {
  content: "\58";
}
.icon-dashboard-icon:before {
  content: "\59";
}
.icon-delete-activation-icon:before {
  content: "\5a";
}
.icon-delete-apple-icon:before {
  content: "\30";
}
.icon-delete-device-icon:before {
  content: "\31";
}
.icon-delete-icon:before {
  content: "\32";
}
.icon-delete-user-icon:before {
  content: "\33";
}
.icon-details-icon:before {
  content: "\34";
}
.icon-device-signal-icon:before {
  content: "\35";
}
.icon-devices-icon:before {
  content: "\36";
}
.icon-direct-call-icon:before {
  content: "\37";
}
.icon-disable-direct-call-icon:before {
  content: "\38";
}
.icon-double-next-icon:before {
  content: "\39";
}
.icon-double-prev-icon:before {
  content: "\21";
}
.icon-download-icon:before {
  content: "\22";
}
.icon-download-perm-android-icon:before {
  content: "\23";
}
.icon-drag-icon:before {
  content: "\24";
}
.icon-edit-app-icon:before {
  content: "\25";
}
.icon-edit-icon:before {
  content: "\26";
}
.icon-edit-policies-icon:before {
  content: "\27";
}
.icon-edit-profile-icon:before {
  content: "\28";
}
.icon-email-icon:before {
  content: "\29";
}
.icon-export-icon:before {
  content: "\2a";
}
.icon-export-info-icon:before {
  content: "\2b";
}
.icon-export-location-icon:before {
  content: "\2c";
}
.icon-files-icon:before {
  content: "\2d";
}
.icon-force-pass-business-icon:before {
  content: "\2e";
}
.icon-force-pass-device-icon:before {
  content: "\2f";
}
.icon-generate-report-icon:before {
  content: "\3a";
}
.icon-global-icon:before {
  content: "\3b";
}
.icon-go-details-icon:before {
  content: "\3c";
}
.icon-groups-icon:before {
  content: "\3d";
}
.icon-history-icon:before {
  content: "\3e";
}
.icon-hour-icon:before {
  content: "\3f";
}
.icon-image-icon:before {
  content: "\40";
}
.icon-inconsistent-devices-icon:before {
  content: "\5b";
}
.icon-info-icon:before {
  content: "\5d";
}
.icon-install-proget-icon:before {
  content: "\5e";
}
.icon-integrations-icon:before {
  content: "\5f";
}
.icon-kiosk-off-icon:before {
  content: "\60";
}
.icon-kiosk-on-icon:before {
  content: "\7b";
}
.icon-left-icon:before {
  content: "\7c";
}
.icon-location-icon:before {
  content: "\7d";
}
.icon-lock-icon:before {
  content: "\7e";
}
.icon-login-lock:before {
  content: "\5c";
}
.icon-logout-icon:before {
  content: "\e000";
}
.icon-lost-mode-off-icon:before {
  content: "\e001";
}
.icon-lost-mode-on-icon:before {
  content: "\e002";
}
.icon-mark-as-app-icon:before {
  content: "\e003";
}
.icon-mark-vip-icon:before {
  content: "\e004";
}
.icon-memmory-icon:before {
  content: "\e005";
}
.icon-microphone-icon:before {
  content: "\e006";
}
.icon-move-group-icon:before {
  content: "\e007";
}
.icon-msg-mtp-icon:before {
  content: "\e008";
}
.icon-my-card-icon:before {
  content: "\e009";
}
.icon-next-icon:before {
  content: "\e00a";
}
.icon-noconnection-icon:before {
  content: "\e00b";
}
.icon-notifications-icon:before {
  content: "\e00c";
}
.icon-number1-icon:before {
  content: "\e00d";
}
.icon-number2-icon:before {
  content: "\e00e";
}
.icon-number3-icon:before {
  content: "\e00f";
}
.icon-number4-icon:before {
  content: "\e010";
}
.icon-number5-icon:before {
  content: "\e011";
}
.icon-number6-icon:before {
  content: "\e012";
}
.icon-number7-icon:before {
  content: "\e013";
}
.icon-ok-icon:before {
  content: "\e014";
}
.icon-operating-devices-icon:before {
  content: "\e015";
}
.icon-options2-icon:before {
  content: "\e016";
}
.icon-options-icon:before {
  content: "\e017";
}
.icon-pass-knox-icon:before {
  content: "\e018";
}
.icon-phone-icon:before {
  content: "\e019";
}
.icon-pie-chart-icon:before {
  content: "\e01a";
}
.icon-pin-icon:before {
  content: "\e01b";
}
.icon-policy-icon:before {
  content: "\e01c";
}
.icon-prev-icon:before {
  content: "\e01d";
}
.icon-profiles-icon:before {
  content: "\e01e";
}
.icon-refresh-icon:before {
  content: "\e01f";
}
.icon-refresh-licence-icon:before {
  content: "\e020";
}
.icon-refresh-pass-icon:before {
  content: "\e021";
}
.icon-refresh-pass-knox-icon:before {
  content: "\e022";
}
.icon-reg-status-icon:before {
  content: "\e023";
}
.icon-remote-icon:before {
  content: "\e024";
}
.icon-repeat-icon:before {
  content: "\e025";
}
.icon-reports-icon:before {
  content: "\e026";
}
.icon-reset-device-icon:before {
  content: "\e027";
}
.icon-restart-authentication-icon:before {
  content: "\e028";
}
.icon-right-icon:before {
  content: "\e029";
}
.icon-run-app-icon:before {
  content: "\e02a";
}
.icon-search-icon:before {
  content: "\e02b";
}
.icon-security-icon:before {
  content: "\e02c";
}
.icon-security-key-icon:before {
  content: "\e02d";
}
.icon-see-icon:before {
  content: "\e02e";
}
.icon-send-activation-icon:before {
  content: "\e02f";
}
.icon-send-alert-msg-device-icon:before {
  content: "\e030";
}
.icon-send-apn-icon:before {
  content: "\e031";
}
.icon-send-msg-device-icon:before {
  content: "\e032";
}
.icon-send-msg-icon:before {
  content: "\e033";
}
.icon-service-icon:before {
  content: "\e034";
}
.icon-service-pass-icon:before {
  content: "\e035";
}
.icon-settings-icon:before {
  content: "\e036";
}
.icon-sms-icon:before {
  content: "\e037";
}
.icon-sort-icon:before {
  content: "\e038";
}
.icon-subtract-icon:before {
  content: "\e039";
}
.icon-support-phone-icon:before {
  content: "\e03a";
}
.icon-synchronize-icon:before {
  content: "\e03b";
}
.icon-system-version-icon:before {
  content: "\e03c";
}
.icon-table-arrow-down:before {
  content: "\e03d";
}
.icon-table-arrow-right:before {
  content: "\e03e";
}
.icon-tasks-icon:before {
  content: "\e03f";
}
.icon-test-icon:before {
  content: "\e040";
}
.icon-turn-off-active-lock-icon:before {
  content: "\e041";
}
.icon-turn-off-bluetooth-icon:before {
  content: "\e042";
}
.icon-turn-on-active-lock-icon:before {
  content: "\e043";
}
.icon-unblock-cleaning-icon:before {
  content: "\e044";
}
.icon-unblock-device-icon:before {
  content: "\e045";
}
.icon-unblock-knox-icon:before {
  content: "\e046";
}
.icon-unblock-shutdown-icon:before {
  content: "\e047";
}
.icon-unblock-uninstall-icon:before {
  content: "\e048";
}
.icon-unblock-user-icon:before {
  content: "\e049";
}
.icon-unlock-icon:before {
  content: "\e04a";
}
.icon-unmark-vip-icon:before {
  content: "\e04b";
}
.icon-update-group-icon:before {
  content: "\e04c";
}
.icon-upload-file-icon:before {
  content: "\e04d";
}
.icon-user-icon-card:before {
  content: "\e04e";
}
.icon-users-icon:before {
  content: "\e04f";
}
.icon-windows-icon:before {
  content: "\e050";
}
.icon-wipe-icon:before {
  content: "\e051";
}
.icon-back-icon:before {
  content: "\61";
}
.icon-bar-chart-icon:before {
  content: "\62";
}
.icon-change-labels2-icon:before {
  content: "\63";
}
.icon-delete-devices-icon:before {
  content: "\64";
}
.icon-delete-policies-icon:before {
  content: "\65";
}
.icon-labels2-icon:before {
  content: "\66";
}
.icon-licenses-icon:before {
  content: "\67";
}
.icon-logout2-icon:before {
  content: "\68";
}
.icon-permissions-icon:before {
  content: "\69";
}
.icon-pie-chart-icon2:before {
  content: "\6a";
}
.icon-stop-app-icon:before {
  content: "\6b";
}
.icon-upate-icon:before {
  content: "\6c";
}
.icon-sepol-icon:before {
  content: "\e052";
}
