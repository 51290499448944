@import '../base/variables';

@mixin proget-clear-button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: normal;
  line-height: 1;
  outline: none;
  padding: 0;
}

@mixin proget-button-base($color, $oppositeColor, $light: false) {
  @include proget-clear-button;

  border-radius: 0.4em;
  border: thin solid;
  cursor: pointer;
  font-family: 'Titillium Web', sans-serif;
  font-size: 12px;
  height: auto;
  line-height: 1.75em;
  min-width: 2em;
  padding: 0.1em 1.3em;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  vertical-align: middle;

  & > * {
    display: inline-block;
    padding: 0;
  }

  i, [class*="p-button-icon"] {
    font-size: 1.5em;
    line-height: 1;
    margin: auto 0.6em auto -0.2em;
    position: static;
    vertical-align: text-bottom;

    &:first-child:last-child {
      margin: 0;
    }
  }

  &.icon-only {
    padding: 0.35em 0.35em 0.2em;
  }

  @if $light {
    background: none;
    border-color: $color;
    color: $color;

    &:not(:disabled) {
      &.active, &:hover {
        background: $color;
        color: $oppositeColor;
      }
    }

    &:disabled {
      &, &:hover {
        background: $system-light-background !important;
        border-color: $default-light-color-text !important;
        color: $default-light-color-text !important;
        cursor: default;
      }
    }
  }

  @else {
    background: $color;
    border-color: $color;
    color: $oppositeColor;

    &:not(:disabled) {
      &.active, &:hover {
        background: $oppositeColor;
        color: $color;
      }
    }

    &:disabled {
      &, &:hover {
        background: $system-light-background !important;
        border-color: $system-light-background !important;
        color: $default-light-color-text !important;
        cursor: default;
      }
    }
  }

  [class*="proget-button"] + & {
    margin-left: 0.45em;
  }
}

// USE THIS:

@mixin proget-button-primary {
  @include proget-button-base($primary-color, $white);
}

@mixin proget-button-secondary {
  @include proget-button-base($default-color-text, $white);
}

@mixin proget-button-danger {
  @include proget-button-base($primary-color, $white);
}

@mixin proget-button-white {
  @include proget-button-base($white, $default-color-text);
}

@mixin proget-button-primary-light {
  @include proget-button-base($primary-color, $white, true);
}

@mixin proget-button-secondary-light {
  @include proget-button-base($default-color-text, $white, true);
}

@mixin proget-button-danger-light {
  @include proget-button-base($primary-color, $white, true);
}

@mixin proget-button-white-light {
  @include proget-button-base($white, $default-color-text, true);
}
