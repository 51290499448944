@import "../base/variables";

$border-radius: $default-border-radius;

.input-group {
  align-items: stretch;
  display: flex;
  margin: 10px 0;
  width: 100%;

  pre,
  textarea,
  button,
  input,
  .input-group-element {
    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  pre {
    flex: 1 1;
    margin: 0;
    text-align: center;
  }

  button {
    & + .proget-input {
      border-left: none;
    }
  }

  .input-group-addon {
    align-items: center;
    background: none;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    color: $default-color-text;
    display: flex;
    font-size: 18px;
    justify-content: center;
    min-width: 1.6em;

    &:first-child {
      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }

    &:last-child {
      border-right: 1px solid $border-color;
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    .icon-container {
      display: inline-block;
      height: 100%;
      line-height: 100%;
      vertical-align: middle;
    }
  }

  .proget-input {
    margin: 0;

    &:enabled:hover {
      border-color: #d6d6d6;
    }

    &:focus {
      box-shadow: initial !important;
      border-color: #d6d6d6;
    }

    & + .input-group-addon {
      border-left: none;
    }
  }
}
