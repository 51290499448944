@import '../base/breakpoint';
@import '../base/layers';
@import '../base/variables';

:root {
  // shared/batch-work/component/work-progress-dialog/work-progress-dialog.component.scss
  --shared-dialog-error-text-color: #{$red};

  // shared/grid-actions/component/grid-actions/grid-actions.component.scss
  --shared-actions-separator-color: #{$gainsboro};
  --shared-action-tooltip-text-color: #{$white};
  --shared-actions-button-disabled-color: #{$gainsboro};

  // shared/grid-control/component/filter-multiselect/filter-multiselect.component.scss
  --shared-filter-multiselect-invalid-color: #{$red};
  --shared-filter-multiselect-placeholder-color: #939095;

  // shared/grid-control/component/filter-select/filter-select.component.scss
  --shared-filter-select-invalid-color: #{$red};

  // shared/grid-control/component/grid-filters/grid-filters.component.scss
  --shared-grid-filters-background: #{$table-row-background};
  --shared-grid-filters-shadow: #{$box-shadow};

  // shared/grid-control/component/grid-paginator/grid-paginator.component.scss
  --shared-paginator-text-color: #959595;

  // shared/toast/module/view/view.component.scss
  --shared-toast-z-index: #{$z-index-toast};
  --shared-toast-text-color: inherit;
  --shared-toast-text-hover-color: inherit;

  // shared/tooltip/component/tooltip-cloud/tooltip-cloud.component.scss
  --shared-tooltip-background: #{$sidebar-color-background};
  --shared-tooltip-border: #{$sidebar-color-background};
  --shared-tooltip-text-color: #{$white};
  --shared-tooltip-z-index: #{$z-index-dialog};

  // shared/input-length-counter/input-length-counter.component.scss
  --shared-input-length-counter-text-color: inherit;

  // shared/radio-button/radio-button.component.scss
  --shared-radio-button-dot-border: #{$gainsboro};
  --shared-radio-button-dot-checked-background: #f8f8f8;
  --shared-radio-button-dot-checked-center-color: #{$white};
  --shared-radio-button-disabled: #e5e5e5;

  // shared/proget-shared/src/listbox/listbox.component.scss
  --shared-listbox-background: #{$white};
  --shared-listbox-color: #{$default-color-text};
  --shared-listbox-border: #{$gainsboro};
  --shared-listbox-header-background: #{$table-header-background};
  --shared-listbox-item-hover-background: #{$default-color-text};
  --shared-listbox-item-hover-color: #{$sidebar-color-active};
  --shared-listbox-item-selected-background: #{$default-color-text};
  --shared-listbox-item-selected-color: #{$white};

  // shared/src/ellipsis;
  --shared-ellipsis-z-index: #{$z-index-toast - 1};

  // shared/src/overlay-panel
  --shared-overlay-panel-z-index: #{$z-index-dialog + 10};

  // shared/src/form/dropdown
  --shared-dropdown-item-hover-background: #{$default-color-text};
  --shared-dropdown-item-hover-color: #{$sidebar-color-active};
  --shared-dropdown-item-selected-background: #{$default-color-text};
  --shared-dropdown-item-selected-color: #{$white};
  --shared-dropdown-font-size: 14px;

  // shared/form/checkbox
  --shared-checkbox-label-size: inherit;
  --shared-checkbox-label-color-disabled: rgba($default-color-text, 0.4);
  --shared-checkbox-border-radius: #{$default-border-radius};

  // shared/input-suggestions
  --shared-input-suggestions-border-radius: #{$default-border-radius};

  // shared/src/form/input-list/input-list-item/input-list-item.component.scss
  --shared-input-list-border-radius: #{$default-border-radius};

  // shared/src/progress-bar
  --shared-progress-bar-border-radius: #{$default-border-radius};
  --shared-progress-bar-background: #{$sidebar-color-active};
  --shared-progress-bar-border-color: #{$default-color-text};

  // shared/src/form/color-picker
  --shared-color-picker-border-radius: #{$default-border-radius};

  // shared/scrollbar
  --shared-scrollbar-thumb-color: #{$scrollbar-thumb-color};
  --shared-scrollbar-thumb-hover-color: #{$scrollbar-thumb-hover-color};

  // shared/ui/countdown-animation
  --shared-countdown-animation-progress-meter-color: #{$blue};
  --shared-countdown-animation-progress-value-color: #{$gray};

  // shared/file-download
  --shared-file-download-progress-toast-title-font-size: 1em;
  --shared-file-download-progress-toast-title-font-weight: bold;
  --shared-file-download-progress-toast-progress-bar-border: solid thin #{$sidebar-color-active};
  --shared-file-download-progress-toast-progress-bar-background: #{$sidebar-color-active};
}
