* {
  box-sizing: border-box;
}

html {
  color: $default-color-text;
  font-size: $default-size;
  height: 100%;
  overscroll-behavior: none;
}

body {
  background-color: $body-color-background;
  font-family: 'Titillium Web', sans-serif;
  font-size: $default-size;
  height: inherit;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

app-root {
  display: block;
  height: inherit;
}

[hidden] {
  display: none !important;
}

a {
  text-decoration: none;
}

pre {
  margin: 0;
}

.batch-work-pending #proget-loader {
  animation-name: pg_loader_hide;
  max-width: 0;
  visibility: hidden;
}

.grid-filters {
  display: block;
  margin-bottom: 20px;
}
