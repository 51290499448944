@import 'base/variables';

@keyframes promptErrorsfadeIn {
  0% { height: 0; }
  1% { height: auto; opacity: 0; }
  100% { opacity: 1; }
}

.proget-dialog  {
  .dialog-content, .dialog-header {
    width: 600px;
  }

  .proget-dialog-floating-wrapper {
    .dialog-content, .dialog-header {
      width: 100%;
    }
  }

  .proget-dialog-static-wrapper, .proget-dialog-floating-wrapper {
    .dialog-container {
      font-size: 14px;
      min-width: 250px;

      .dialog-message {
        text-align: center;
      }
      .dialog-content {
        overflow-wrap: break-word;
        word-wrap: break-word;
        z-index: 1;

        .dialog-content-scrollbar-content {
          padding: 28px;
        }

        .dialog-message {
          margin-bottom: 15px;
        }
      }
      .dialog-footer {
        border-top: 1px solid var(--border-color-1);
        padding: 20px;
        text-align: center;
      }
    }
  }
  .dialog-button {
    min-width: 60px;
  }
  .dialog-input {
    background: var(--background-color-default);
    border: 1px solid var(--border-color-1);
    color: var(--text-color-1);
    font-size: 14px;
    margin: 0;
    outline: 0;
    padding: 0.429em;

    &.ng-touched {
      &.ng-invalid {
        border-color: var(--border-color-1);
      }
    }
  }
  .proget-dialog-prompt {
    .dialog-input {
      width: 100%;
    }
    .dialog-input-counter {
      color: var(--text-color-2);
      font-size: 12px;
      font-style: italic;
      margin-top: 5px;
      text-align: right;
    }
  }
}
