@import 'variables';
@import '../mixins/buttons';

.proget-clear-button {
  @include proget-clear-button;
}

.proget-button, .proget-button-primary {
  @include proget-button-primary;
}

.proget-button-secondary {
  @include proget-button-secondary;
}

.proget-button-danger {
  @include proget-button-danger;
}

.proget-button-white {
  @include proget-button-white;
}

.proget-button-light, .proget-button-primary-light {
  @include proget-button-primary-light;
}

.proget-button-secondary-light {
  @include proget-button-secondary-light;
}

.proget-button-danger-light {
  @include proget-button-danger-light;
}

.proget-button-white-light {
  @include proget-button-white-light;
}

.button-separate-margin {
  margin-left: 0.45em !important;
}

// ----

.auth-submit-button {
  background-color: $primary-color;
  border: none;
  color: $white;
  cursor: pointer;
  height: 30px;
  line-height: normal;
  outline: none;
  padding: .25em 1em;
  transition: background-color .2s;
  width: 100%;

  &:disabled {
    cursor: default;
    opacity: .35;
  }
  &:hover:not(:disabled) {
    color: $white;
    outline: 0;
  }
}
