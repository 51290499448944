$font-path: '/assets/fonts';

/* titillium-web-200 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 200;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-200.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web ExtraLight'), local('TitilliumWeb-ExtraLight'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-200italic - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 200;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-200italic.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web ExtraLight Italic'), local('TitilliumWeb-ExtraLightItalic'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-200italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-300 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Light'), local('TitilliumWeb-Light'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-300italic - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 300;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-300italic.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Light Italic'), local('TitilliumWeb-LightItalic'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-300italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-regular - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-italic - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Italic'), local('TitilliumWeb-Italic'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600italic - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 600;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-600italic.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web SemiBold Italic'), local('TitilliumWeb-SemiBoldItalic'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-600italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700italic - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 700;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Bold Italic'), local('TitilliumWeb-BoldItalic'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-700italic.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-900 - latin_latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 900;
  src: url('#{$font-path}/titillium-web-v6-latin_latin-ext-900.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Black'), local('TitilliumWeb-Black'),
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-900.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}/titillium-web-v6-latin_latin-ext-900.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
