@import 'mixins/buttons';

app-grid-paginator {
  .paginator-button {
    @include proget-button-primary-light;

    &.arrow-button {
      border: none;
    }
  }
}
app-tooltip-cloud {
  .proget-tooltip {
    line-height: 1.33em !important;
  }
}
.proget-table {
  .table-header {
    position: sticky !important;
    top: 51px;

    .proget-dialog & {
      top: -29px !important;
    }
    .proget-table & { // disable sticky for nested tables
      position: relative !important;;
      top: auto !important;
    }
  }
}
.tooltip-nowrap {
  white-space: nowrap;
}
.proget-input {
  appearance: none;
  background: $white;
  border: 1px solid $border-color;
  border-radius: $default-border-radius;
  color: $default-color-text;
  font-size: 14px;
  line-height: 18px;
  padding: 0.429em;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  width: 100%;

  &:disabled {
    opacity: .4;
  }

  &:enabled {
    &:hover {
      border-color: $gainsboro;
    }
    &:focus {
      border-color: $gainsboro;
      box-shadow: $box-shadow;
      outline: 0 none;
      outline-offset: 0;
    }
  }

  &.ng-touched.ng-invalid {
    border-color: $red;
  }
}
.calendar-input-group {
  .range-separator {
    line-height: 18px;
  }
}
