$image-path: '/assets/images';

$black: #000000;
$black-transparent-25: rgba(0, 0, 0, 0.25);
$black-transparent-50: rgba(0, 0, 0, 0.5);
$blue: #6190bf;
$carbon: #1c1d1e;
$carbon-light: #343536;
$gainsboro: #d9d9d9;
$gray: #f9f9f9;
$gray-dark-1: #b7b8b9;
$gray-dark-2: #979899;
$gray-dark-3: #58595a;
$gray-light: #d3d3d3;
$green: #5cb85c;
$night-rider: #333333;
$red: #d9534f;
$tranparent: rgba(255, 255, 255, 0);
$yellow: #f0ad4e;
$white: #ffffff;

$primary-color: #2383A4;

$system-light-background: #eff0f5;
$table-header-background: #eff0f5;
$table-row-background: #f9faff;
$border-color: #d4d5d9;

$default-color-text: #1b2a38;
$default-light-color-text: #939095;
$oposite-color-text: $white;

$default-border-radius: 3px;
$default-padding: 16px;
$default-size: 14px;

$box-shadow: 0 0 5px $border-color;

$scrollbar-thumb-color: #a5a4a3;
$scrollbar-thumb-hover-color: #939095;

$body-color-background: #edf0f5;

$footer-size: 50px;
$header-size: 50px;

$sidebar-color-background: $carbon;
$sidebar-color-active: $primary-color;
$sidebar-full-size: 200px;
$sidebar-slim-size: 51px;

$menu-item-font-size: 16px;
$menu-item-color-active-text: $blue;
$menu-item-color-active-background: #2b2d2f;
$menu-item-color-active-gradient: linear-gradient(90deg, $menu-item-color-active-background 0%, $default-color-text 100%);
$submenu-item-font-size: 13px;
