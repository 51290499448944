:root {
  // danger
  --color-danger-background: #fbefee;
  --color-danger: #d9534f;
  --color-danger-border: #c9302c;
  // warning
  --color-warning-background: #fceddd;
  --color-warning: #eb8f2c;
  --color-warning-border: #d07514;
  // success
  --color-success-background: #eefbf0;
  --color-success: #008000;
  --color-success-border: #004d00;
  // info
  --color-info-background: #eef7fb;
  --color-info: #0891cf;
  --color-info-border: #044868;
  // text
  --text-color-1: #1f1c1a;
  --text-color-1-disabled: #a5a4a3; // 40% --text-color-1 on --background-color-default
  --text-color-2: #939095;
  --text-color-2-disabled: #d4d3d5; // 40% --text-color-2 on --background-color-default
  --text-color-contrast: #ffffff;
  // border
  --border-color-1: #d9d9d9;
  --border-color-1-disabled: #f0f0f0; // 40% --border-color-1 on --background-color-default
  --border-color-2: #e5e5e5;
  --border-color-2-disabled: #f5f5f5; // 40% --border-color-2 on --background-color-default
  // background
  --background-color-default: #ffffff;
  --background-color-highlight-1: #f4f4f4;
  --background-color-highlight-2: #e5e5e5;
  --background-color-transparent: #1f1c1a0d;
  --background-color-body: #e5e5e5;
  --background-color-footer: #414042;
  --background-color-sidebar: #dddddd;
  // box-shadow
  --box-shadow: 3px 3px 3px 0 rgba(50, 50, 50, 0.15);
  // google
  --google-background-color: #ffffff;
  --google-text-color: #757575;
  // border-radius
  --border-radius: 5px;
  // pql-colors
  --pql-key-color: #339cff;
  --pql-comparison-operator-color: #5bcd9d;
  --pql-logical-operator-color: #7163c2;
}
