.align-center {
  text-align: center;
}
.align-justify {
  text-align: justify;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}

.full-width {
  width: 100%;
}

.vertical-align {
  &-middle {
    vertical-align: middle;
  }
}

.float {
  &-right {
    float: right;
  }
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.wrap-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.word-break {
  word-break: break-all;
}
.position-relative {
  position: relative;
}
.text-uppercase {
  text-transform: uppercase;
}
